import { useAsync, IfPending, IfFulfilled, IfRejected } from 'react-async';
import './App.css';
import {useEffect, useState} from 'react';
import {Cape} from '@capeprivacy/cape-sdk';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';


const functionID = 'n8iWBbPdaBweqfsVC9dvPc';
const capeToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlBVMlpQR0d6a3gzbEQzX0JnOUdiRSJ9.eyJpc3MiOiJodHRwczovL21hZXN0cm8tZGV2LnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDEwNTE4MTQ3Mjc1OTYxMDYzMTgyNCIsImF1ZCI6WyJodHRwczovL25ld2RlbW8uY2FwZXByaXZhY3kuY29tL3YxLyIsImh0dHBzOi8vbWFlc3Ryby1kZXYudXMuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY2MDE0ODI0MiwiZXhwIjoxNjYyNzQwMjQyLCJhenAiOiJ5UW5vYmtPcjFwdmREQXlYd05vamtOVjJJUGJOZlh4eCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgb2ZmbGluZV9hY2Nlc3MifQ.AdfbJtoPqkcLcBPvn-EQfHAb2YC3sqxsEca5G6tdCaOVdQ-gaEQZb4H_0dQuxfdR4ORiqc9vmpX10puf4CD8OYFf45g1pcn7iQguSHks8XJQHTDGg-BD9EDJxDns8w2TPC59ETYykHinPrJxPifF3uzTLIByqmFc5cQ714H1JTNbZ5NXoV_2-izPBni516WCSMtv6Yq59XeO2699SaY58c5zsBM4E24DdmnB8BSCv3VHAyR6CIz74tP8cJ4Bn2ALJ22JIeDIy7WxHs5AAhA5FB-T0dWR_NLF5GFF9HM5yy_63lFRQm6aRo4hNeOMj0MGA8sBs1RAAwswSUWd1KxQEQ';
const client = new Cape({authToken: capeToken, capeApiUrl: 'wss://hackathon.capeprivacy.com', verbose: false})

const Post = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [message, setMessage] = useState("");

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({
                audience: 'https://newdemo.capeprivacy.com/v1/',
                scope: 'read:posts'
            })
            console.log('token issss', token)

            try {
                const user = await client.run({id: document.location.pathname.substring(1), data: token})
                setMessage(user)
            } catch (e) {
                setMessage("You are not authorized to view this")
            }

        })();
    }, [getAccessTokenSilently])

    return message !== "" ? (<pre>{message}</pre>) : <pre>Loading ...</pre>
}

function LogoutButton() {
    const {
        isAuthenticated,
        logout,
    } = useAuth0();

    return isAuthenticated && (
        <button onClick={() => {
            logout({ returnTo: window.location.origin });
        }}>Log out</button>
    );
}

const App = () => {
  return <div className="App">
            <Post/>
            <LogoutButton/>
         </div>
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
