import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="maestro-dev.us.auth0.com"
        clientId="NxlixvQpxz6sIDaFbg3mTljYiYPewhUY"
        redirectUri={window.location.origin}
        scope='read:posts'
        audience='https://newdemo.capeprivacy.com/v1/'
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
